
import { Component, Vue } from "vue-property-decorator";
// import Navbar from "@/components/Utility/Navbar.vue";
// import Footer from "@/components/Utility/Footer.vue";
import BackToTop from "@/components/Utility/BackToTop.vue";

@Component({
  components: {
    // Navbar,
    // Footer,
    BackToTop,
  },
})
export default class Academy extends Vue {}
